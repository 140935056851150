import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      // border: 'solid black 1px', // DEBUG
      textAlign: 'center',
    }
  },
  selected: { color: 'black' },
}));

const voteLabel = value => {
  const DIV = 1000;
  return value > DIV ? _.ceil(value / DIV, 1) + 'K' : (value || 0);
};

function getTooltipTitleUp(value, disabled) {
  if (disabled) {
    return "You cannot rate your content";
  }
  return value > 0
    ? "You like this"
    : "I like this";
};

function getTooltipTitleDown(value, disabled) {
  if (disabled) {
    return "You cannot rate your content";
  }
  return value > 0
    ? "You dislike this"
    : "I don't like this";
};

function ThumbRate(props) {
  const {
    className,
    value,
    totalUp,
    totalDown,
    disabled,
    onToggleUp,
    onToggleDown,
  } = props;
  const classes = useStyles();

  const handleToggleUp = (event) => {
    event.preventDefault();
    if (disabled) return;
    onToggleUp();
  }

  const handleToggleDown = (event) => {
    event.preventDefault();
    if (disabled) return;
    onToggleDown();
  }



  return (
    <div className={clsx(classes.root, className)}>

      <div>
        <Tooltip title={getTooltipTitleUp(value, disabled)} placement="top" arrow>
          <IconButton
            className={clsx({
              [classes.selected]: value > 0,
            })}
            size="small"
            color="inherit"
            aria-label="like"
            onClick={handleToggleUp}
          >
            <ThumbUpIcon
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.up}>
        {voteLabel(totalUp)}
      </div>

      <div>
        <Tooltip title={getTooltipTitleDown(value, disabled)} placement="top" arrow>
          <IconButton
            className={clsx({
              [classes.selected]: value < 0,
            })}
            // disabled={disabled}
            size="small"
            color="inherit"
            aria-label="dislike"
            onClick={handleToggleDown}
          >
            <ThumbDownIcon
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.down}>
        {voteLabel(totalDown)}
      </div>

    </div>
  );
}

// @todo
// ThumbRate.propTypes = {
//   onToggleUp: PropTypes.func.isRequired,
//   onToggleDown: PropTypes.func.isRequired,
// };

export default ThumbRate;
