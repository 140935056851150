import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

// const IOSSwitch = withStyles(theme => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: theme.spacing(1),
//   },
//   switchBase: {
//     padding: 1,
//     '&$checked': {
//       transform: 'translateX(16px)',
//       color: theme.palette.tertiary.contrastText,
//       '& + $track': {
//         backgroundColor: theme.palette.tertiary.main,
//         opacity: 1,
//         border: 'none',
//       },
//     },
//     '&$focusVisible $thumb': {
//       // color: '#52d869', // ???
//       color: theme.palette.tertiary.main,
//       border: '6px solid #fff',
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     // backgroundColor: theme.palette.primary.main, // NO
//     opacity: 1,
//     transition: theme.transitions.create(['background-color', 'border']),
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   const theme = useTheme();
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//       icon={<RemoveIcon />}
//       checkedIcon={<AddIcon />}
//     />
//   );
// });

// export default IOSSwitch;


const AntSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    display: 'flex',
  },
  // switchBase: {
  //   padding: 1,
  //   backgroundColor: theme.palette.primary.main,
  //   color: theme.palette.primary.contrastText,
  //   '&$checked': {
  //     transform: 'translateX(16px)',
  //     borderColor: theme.palette.grey[500],
  //     backgroundColor: theme.palette.tertiary.main,
  //     color: theme.palette.tertiary.contrastText,
  //     '& + $track': {
  //       opacity: 1,
  //       backgroundColor: theme.palette.tertiary.main,
  //       borderColor: theme.palette.tertiary.main,
  //       // borderColor: theme.palette.grey[500],
  //     },
  //   },
  // },
  switchBase: {
    padding: 1,
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    '&$checked': {
      transform: 'translateX(16px)',
      borderColor: theme.palette.grey[500],
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function (props) {
  return (
    <AntSwitch
      icon={<RemoveIcon />}
      checkedIcon={<AddIcon />}
      {...props}
    />
  );
}
