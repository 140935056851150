import React, { useState, useRef, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MicIcon from '@material-ui/icons/Mic';
import TelegramIcon from '@material-ui/icons/Telegram';

import SignInOrSignup from 'features/auth/SignInOrSignup';
import Recorder from 'components/recorder';
import EmojiSlider from 'components/EmojiSlider';
import PositionTimeline from '../positions/PositionTimeline';
// import PositionAgreementCast from '../positions/PositionAgreementCast';
// import PositionDimensionsCast from '../positions/PositionDimensionsCast';
import { addClaim, editClaim, saveClaimMedia, focusClaim } from '../store/claims.slice';
// import SliderIcon from 'icons/Slider';

import { makeGetClaims } from '../store/discuss.selectors';
import { collapsePositionCard, expandPositionCard } from '../store/discuss.slice';
import { fetchSummary } from '../store/summary.slice';

const actionHeight = 44;

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: 10, // @todo move in the theme???
    borderBottomRightRadius: 10, // @todo move in the theme???
  },

  closeButton: {
    position: 'absolute', top: 10, right: 10, // @todo use theme spacing ?
  },

  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0 0 10px 10px', // @todo take from the theme???
    // backgroundColor: theme.palette.background.paper,
    padding: '5px',
    // textAlign: 'center',
    '& button': { marginRight: '5px' },
  },

  actions: {
    // border: 'solid black 1px', // DEBUG
    borderRadius: '0 0 10px 10px', // @todo take from the theme???
    backgroundColor: theme.palette.grey[300],
    display: 'flex', alignItems: 'flex-end',
    '& > *': { margin: 5 },
    '& > button': { display: 'none' },
    '& > form': {
      width: '100%', // Fix IE 11 issue.
      minHeight: actionHeight,
    },
    '& .MuiCollapse-wrapperInner': {
      display: 'flex', alignItems: 'center',
      height: actionHeight,
    },
  },
    input: {
      backgroundColor: theme.palette.background.paper,
    },
    sendAction: {
      display: 'none',
      width: 160, height: 44,
      '& > button': { margin: 5 },
    },
    opposingButton: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    },
    supportingButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    slider: {
      padding: '75px 80px 10px 80px',
    },
  show: { display: 'block !important' },
  filler: { flex: '1 1 auto' },
}));

function ClaimCreate(props) {
  const {
    className,
    pending,
    // error,
    position,
    // positionAgreement,
    lastClaim,
    focused,
    authUser,
    onSave,
    onCollapse,
    onExpand,
  } = props;
  const classes = useStyles();
  const rootRef = useRef(null);
  const formRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [slidePristine, setSlidePristine] = useState(true);
  const [openAgreement, setOpenAgreement] = useState(false);
  // const [openPreview, setOpenPreview] = useState(false);
  const [openRecorder, setOpenRecorder] = useState(false);
  const [type, setType] = useState(null);
  const [agreement, setAgreement] = useState(0);
  const [values, setValues] = useState({
    positionAgreement: 0,
    text: '',
    media: null,
  });

  const handleOpenDialog = () => {
    if (authUser === null) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };

  const startRecording = () => {
    setOpenRecorder(true);
  }

  const stopRecording = () => {
    setOpenRecorder(false);
  }

  const handleOpenActions = argumentType => (event) => {
    event.preventDefault();
    if (_.isNull(authUser)) {
      setOpenDialog(true);
    } else {
      setType(argumentType);
      onCollapse(position.id);
    }
  };

  const handleTyping = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    // console.log('handleTyping', value);
    if (_.isEmpty(value.trim())) {
      // console.log('handleTyping', 'close', openAgreement);
      setOpenAgreement(false);
    } else {
      // console.log('handleTyping', 'open', openAgreement);
      setOpenAgreement(true);
    }
  }

  const handleDone = (event, record) => {
    setValues({ ...values, media: record });
    setOpenRecorder(false);
    // @todo open chooser box
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (pending) return;
    if (values.text === '' && values.media === null) return;
    return onSave({
      ...values,
      type,
      positionAgreement: agreement,
      // positionAgreement: _.get(positionAgreement, 'level', 0),
    })
      .then(res => {
        if (res.meta.requestStatus === 'rejected') {
          // for debug ONLY
          console.warn('ClaimCreate.handleSave()', res); // DEBUG
        } else if (res.meta.requestStatus === 'fulfilled') {
          // console.log('aaaaaa');
          // @todo fix this, somethime doesn't work
          formRef.current.reset();
          setOpenRecorder(false);
          setValues({ ...values, text: '', media: null });
          setType(null);
          onExpand(position.id);
        }
      });
  }

  const handleSlide = (event, value) => {
    event.preventDefault();
    setSlidePristine(false);
    setAgreement(value);
  }

  // @todo find a way how to open the collapse in the reverse way
  // const buildAgreementCollapse = () => (
  //   <Collapse
  //     in={openAgreement}
  //     timeout="auto"
  //     unmountOnExit
  //   >
  //     <PositionAgreementCast
  //       position={position}
  //       onSlideCommitted={() => {
  //         // @todo improve the code ?
  //         formRef.current.elements[0].focus();
  //       }}
  //     />
  //   </Collapse>
  // );
  const buildAgreementCollapse = () => (
    <Collapse
      in={!_.isNull(type)}
      style={{ position: 'relative' }}
      timeout="auto"
      unmountOnExit
    >
      {/* @todo put is in a component */}
      <IconButton
        className={classes.closeButton}
        aria-label="close argument action panel"
        size="small"
        onClick={() => {
          setOpenAgreement(false);
          setType(null);
          onExpand(position.id);
        }}
      >
        <CloseIcon />
      </IconButton>
      <EmojiSlider
        className={classes.slider}
        name={`position-agreement-${position.id}`}
        value={agreement}
        timestamp={_.get(lastClaim, 'metadata.createdDate', null)}
        pastTense={slidePristine && Boolean(lastClaim)}
        onChange={handleSlide}
        // onChangeCommitted={handleCastCommitted}
        onChangeCommitted={() => {
          formRef.current.elements[0].focus();
        }}
      />
      {lastClaim && <>
        <br /> {/* @todo use CSS */}
        <PositionTimeline
          // show={} // @todo
          position={position}
        />
      </>}
    </Collapse>
  );

  const buildInputForm = () => (
    <form ref={formRef} autoComplete="off">
      <TextField
        name="text"
        placeholder="Briefly enter your argument here"
        variant="outlined" size="small"
        multiline rowsMax={5}
        fullWidth
        autoFocus
        InputProps={{
          className: classes.input,
        }}
        onChange={handleTyping}
      />
    </form>
  );

  const buildAddClaimButtons = () => (
    <div className={classes.buttons}>
      <Button
        // variant={type === 'OPPOSING' ? 'contained' : 'outlined'}
        variant={type === 'OPPOSING' ? 'outlined' : 'contained'}
        aria-label="expand opposing action panel"
        size="small"
        // color="secondary"
        // style={{ backgroundColor: 'rgb(255, 163, 68)' }} // temp
        style={
          type === 'OPPOSING' ? {
            // borderColor: 'rgb(255, 163, 68) !important',
            // color: 'rgb(255, 163, 68) !important',
          } : {
            backgroundColor: 'rgb(255, 163, 68)',
            color: 'white',
          }
        } // temp
        onClick={handleOpenActions('OPPOSING')}
      >Add CON
      </Button>
      <Button
        // variant={type === 'SUPPORTING' ? 'contained' : 'outlined'}
        variant={type === 'SUPPORTING' ? 'outlined' : 'contained'}
        aria-label="expand supporting action panel"
        size="small"
        color="primary"
        onClick={handleOpenActions('SUPPORTING')}
        >Add PRO
      </Button>
    </div>
  );

  // const buildAddClaimButtons = () => (
  //   <div className={classes.buttons}>
  //     <div>
  //       &nbsp;
  //       <Button
  //         // variant={type === 'OPPOSING' ? 'contained' : 'outlined'}
  //         variant={type === 'OPPOSING' ? 'outlined' : 'contained'}
  //         aria-label="expand opposing action panel"
  //         size="small"
  //         // color="secondary"
  //         style={{ backgroundColor: 'rgb(255, 163, 68)' }} // temp
  //         onClick={handleOpenActions('OPPOSING')}
  //       >Add CON
  //       </Button>
  //       <Button
  //         // variant={type === 'SUPPORTING' ? 'contained' : 'outlined'}
  //         variant={type === 'SUPPORTING' ? 'outlined' : 'contained'}
  //         aria-label="expand supporting action panel"
  //         size="small"
  //         color="primary"
  //         onClick={handleOpenActions('SUPPORTING')}
  //         >Add PRO
  //       </Button>
  //     </div>
  //     <div className={classes.filler}>&nbsp;</div>
  //     <div>
  //       {/* <PositionDimensionsCast position={position} /> */}
  //     </div>
  //   </div>
  // );

  const buildActionsPanel = () => (
    <Collapse
      in={!_.isNull(type)}
      style={{position: 'relative'}}
      timeout="auto"
      unmountOnExit
    >
      <div className={classes.actions} onClick={handleOpenDialog}>
        <AttachmentAction show={true} />
        {/* <AgreementAction show={!openAgreement} /> */}
        {buildInputForm()}
        {/* <RecordingAction show={values.text === '' && values.media === null} />
        <SendAction show={values.text !== ''} /> */}
        <SendAction show={true} />
      </div>
    </Collapse>
  );

  // const AgreementAction = memo(({ show }) => (
  //   <>
  //     <IconButton
  //       aria-label="expand position agreement"
  //       className={clsx({ [classes.show]: show })}
  //       onClick={() => { setOpenAgreement(true) }}
  //     >
  //       <SliderIcon />
  //       {/*
  //         @todo
  //         if agreement already cast, show emoji.
  //         it needs to preload agreementPosition in loadDiscussion
  //       */}
  //     </IconButton>
  //     <IconButton
  //       aria-label="collapse agreement"
  //       className={clsx({ [classes.show]: !show })}
  //       onClick={() => { setOpenAgreement(false) }}
  //     >
  //       <CloseIcon />
  //     </IconButton>
  //   </>
  // ));

  const AttachmentAction = memo(({ show }) => (
    <>
      <IconButton
        aria-label="attach an evidence"
        className={clsx({ [classes.show]: show })}
        // onClick={()=>{ setOpenAttachment(true) }}
      >
        {/* <AttachFileIcon /> */}
      </IconButton>
      {/* <DropzoneDialog
        // acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={openAttachment}
        onClose={() => setOpenAttachment(false)}
        onSave={files => {
          console.log('Files:', files); // DEBUG
          setValues({ ...values, attachments: files });
          setOpenAttachment(false);
          setOpenPreview(true);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      /> */}
    </>
  ));

  const RecordingAction = memo(({ show }) => (
    <>
      <Collapse
        in={openRecorder}
        orientation="horizontal" // @tod wait next release?
        unmountOnExit
      >
        <Recorder
          start={true}
          onClear={stopRecording}
          onDone={handleDone}
        />
      </Collapse>
      <IconButton
        aria-label="start record argument"
        className={clsx({
          [classes.show]: show && !openRecorder
        })}
        disabled={authUser === null || pending}
        onClick={startRecording}
      >
        <MicIcon />
      </IconButton>
    </>
  ));

  const SendAction = memo(({ show }) => (
    <IconButton
      aria-label="add text argument"
      className={clsx({ [classes.show]: show })}
      disabled={pending}
      onClick={handleSave}
    >
      <TelegramIcon />
    </IconButton>
  ));

  useEffect(() => {
    if (focused) {
      // @todo implement a scroll observer? To know when the scroll if terminated
      // setTimeout(() => {
      //   formRef.current.elements[0].focus();
      // }, 1000); // @todo now the form is collapsed, this feature needs a new design,
                   // maybe open the OPPOSING by default
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [focused]);

  useEffect(() => {
    if (!_.isNull(type)) {
    // if (openAgreement) {
      setTimeout(() => {
        rootRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 500);
    }
  }, [type]);
  // }, [openAgreement]);

  useEffect(() => {
    if (lastClaim) {
      setAgreement(lastClaim.positionAgreement);
      setSlidePristine(true);
    }
  }, [lastClaim]);

  return (
    <div ref={rootRef} className={clsx(classes.root, className)}>
      {buildAgreementCollapse()}
      {buildAddClaimButtons()}
      {buildActionsPanel()}
      <SignInOrSignup
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
      />
    </div>
  );
}

const getLastOwnedClaim = (claims, owner) => {
  if (Boolean(owner)) {
    const owned = _.filter(claims, obj => obj.metadata.createdBy === owner.uid);
    const ordered = _.orderBy(owned, 'metadata.createdDate', 'asc');
    return _.last(ordered);
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const positionId = ownProps.position.id;
  const claimsState = state.discuss.claims;
  const getClaims = makeGetClaims();
  const authUser = state.auth.profile;
  // const pAgreementsState = state.discuss.positionAgreements;

  return {
    pending: claimsState.pending,
    error: claimsState.error,
    //positionAgreement: _.get(pAgreementsState, `byId[${positionId}].data`, null),
    focused: claimsState.focusedFormId === positionId,
    lastClaim: getLastOwnedClaim(getClaims(state, ownProps), authUser),
    authUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSave: async values => {
      let addedClaimResult, editedClaimResult;
      let addedClaimStatus = true,
          savedMediaStatus = true,
          editedClaimStatus = true;
      addedClaimResult = await dispatch(addClaim({
        ...values,
        debateId: ownProps.position.debateId,
        positionId: ownProps.position.id,
      }));
      addedClaimStatus = addedClaimResult.meta.requestStatus === 'fulfilled';
      if (addedClaimStatus) {
        if (values.media !== null) {
        // if (_.has(values, 'media')) {
          const media = _.get(values, 'media');
          const claim = addedClaimResult.payload;
          const savedMedia = await dispatch(saveClaimMedia({media, claim}))
          savedMediaStatus = savedMedia.meta.requestStatus === 'fulfilled';
          if (savedMediaStatus) {
            const claim = savedMedia.payload;
            editedClaimResult = await dispatch(editClaim(claim));
            editedClaimStatus = editedClaimResult.meta.requestStatus === 'fulfilled';
          }
        }
      }
      const result = Boolean(editedClaimResult) ? editedClaimResult : addedClaimResult;
      if (addedClaimStatus && savedMediaStatus && editedClaimStatus) {
        // dispatch(letScrollToBottom());
        const debateId = result.payload.debateId;
        const claimId = result.payload.id;
        dispatch(focusClaim(claimId));
        // dispatch(fetchSummary(debateId)); // @todo
      }
      return result;
    },
    onCollapse: (positionId) => dispatch(collapsePositionCard(positionId)),
    onExpand: (positionId) => dispatch(expandPositionCard(positionId)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimCreate);
