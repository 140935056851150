import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import Moment from 'react-moment';
import Bounce from 'react-reveal/Bounce';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10, // @todo move in the theme???
    position: 'relative',
    overflow: 'visible',
    // minWidth: 538,
  },
    colors: {
      backgroundColor: 'white', // @todo card color from theme
    },
    cBordered: {
      border: `3px solid ${theme.palette.secondary.main}`,
      borderBottom: 0,
      borderTopLeftRadius: 10, // @todo take it from theme
      borderTopRightRadius: 10, // @todo take it from theme
    },
    abBordered: {
      border: `3px solid ${theme.palette.secondary.main}`,
      borderTop: 0,
    },
  arrow: {
    // border: 'solid black 1px', // DEBUG
    position: 'absolute', top: 14,
    overflow: 'hidden',
    width: 12, height: 30,
    '& > span': {
      display: 'block',
      position: 'absolute', top: 0,
      width: 30, height: 30,
      transform: 'rotate(45deg)',
    },
  },
    arrowLeft: {
      left: '100%',
      '& > span': { right: '50%' },
    },
    arrowRight: {
      right: '100%',
      '& > span': { left: '50%' },
    },
  content: {
    position: 'relative',
    // padding: 10, // @todo find the proper card padding
    fontSize: 20,
    // padding: '15px 30px',
    padding: '15px 30px 0px 30px',
  },
    header: {
      display: 'flex',
      marginBottom: 5,
      '& > div': {
        lineHeight: '22px',
        fontSize: '10px',
      },
    },
      user: {
        // borderRadius: '7px',
        // backgroundColor: 'rgb(250, 67, 41)',
        // padding: '0px 5px',
        // fontWeight: 'bold',
        // color: 'white',
      },
      date: {},
    media: {
      // border: 'solid black 1px', // @todo verticalAlign
      textAlign: 'center',
    },
    text: {
      // marginBottom: 10,
      paddingBottom: 10,
    },
  actionbar: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    '& > *': { display: 'inline-flex' }
  },
  actionbarColors: {
    color: theme.palette.grey[600],
  },
  collapse: {
    // borderBottomLeftRadius: 10, // @todo move in the theme???
    // borderBottomRightRadius: 10, // @todo move in the theme???
    backgroundColor: 'white', // @todo card color from theme
  },
  filler: { flex: '1 1 auto' },
  invert: { flexDirection: 'row-reverse' },
}));

// const CollapseButton = ({classes, expanded, onClick}) => (
//   <IconButton
//     className={clsx(classes.expand, {
//       [classes.expandOpen]: expanded,
//     })}
//     size="small"
//     color="inherit"
//     aria-label="show arguments"
//     aria-expanded={expanded}
//     onClick={onClick}
//   >
//     <ExpandMoreIcon />
//   </IconButton>
// )

// @todo make a util function or find a proper lib
// const getAlign = (side) => {
//   return side === 'left' ? 'right' : 'left';
// };

const getText = (entity) => {
  return _.has(entity, 'title') ? entity.title : entity.text;
};

function PositionCard(props) {
  const {
    classes,
    className,
    side,
    focused, onFocus,
    bounced,
    expanded,
    expandButton,
    owner,
    parent,
    entity,
    reflect,
    actionPanelLeft,
    actionPanelRight,
    createClaim,
    children,
  } = props;
  const classesBase = useStyles();
  const _classes = { ...classesBase, ...classes };
  const titleRef = useRef(null);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const buildHeader = () => (
    <div className={clsx(_classes.header, {
      [_classes.invert]: side === 'right',
    })}>
      <div className={_classes.user}>{owner}</div>
      <div className={_classes.filler}>&nbsp;</div>
      <div className={_classes.date}>
        <Moment fromNow>{entity.metadata.createdDate}</Moment>
      </div>
    </div>
  );

  const buildActionPanel = () => (<>
    <div>
      {/* {expandable && (
        <CollapseButton
          classes={_classes}
          expanded={expanded}
          onClick={handleExpandClick}
        />
      )} */}
      {expandButton}
      {actionPanelLeft}
    </div>
    <div className={_classes.filler}>&nbsp;</div>
    <div>
      {actionPanelRight}
    </div>
  </>);

  const Content = () => {
    return entity.media
      ? <div ref={titleRef} className={_classes.media}>
          <audio controls>
            <source src={entity.media} type="audio/webm" />
          </audio>
          {reflect}
        </div>
      : <div ref={titleRef} className={_classes.text}>
          {getText(entity)} {reflect}
        </div>;
  };

  const CardArrow = memo(() => (
    <div className={clsx(_classes.arrow, {
      [_classes.arrowLeft]: side === 'left',
      [_classes.arrowRight]: side === 'right',
    })}>
      <span className={clsx(_classes.colors)}>&nbsp;</span>
    </div>
  ));

  // @todo elevate card header example
  // @see https://stackoverflow.com/questions/60628435/elevate-the-card-header-in-material-ui-react-cards-with-css

  const cbFocus = useCallback(onFocus, []);
  useEffect(() => {
    if (focused) {
      setTimeout(() => {
        titleRef.current.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
          block: 'center',
        });
      }, 500);
      setTimeout(() => {
        cbFocus();
      }, 10000);
    }
  }, [focused, cbFocus]);

  // console.log('PositionCard', props); // DEBUG

  return (
    <Card
      className={clsx(_classes.root, _classes.colors, className)}
      raised={true}
    >
      {side && <CardArrow caFocused={focused} />}
      <CardContent className={clsx(_classes.content, {
        [_classes.cBordered]: focused,
      })}>
        {buildHeader()}

        {parent}

        {bounced
          ? <Bounce>
              <Content />
            </Bounce>
          : <Content />
        }
      </CardContent>

      <CardActions className={clsx(_classes.actionbar, _classes.actionbarColors, {
        [_classes.abBordered]: focused,
      })} disableSpacing>
        {buildActionPanel()}
      </CardActions>

      <Collapse
        className={_classes.collapse}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>

      {createClaim}

    </Card>
  );
}

PositionCard.propTypes = {
  entity: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired, // @todo think about this
};

export default PositionCard;
